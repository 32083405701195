@import '../../styles/globals.scss';
@import "../../styles/widget/faq.css";
@import "../../styles/widget/tabs.css";
@import "../../styles/widget/status-message.css";
@import "../../styles/widget/quick-link.css";

.field--name-body .ck.ck-editor__main > .ck-editor__editable {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity));
    padding: 0 2em;
}

.ck-widget {
    margin: 1em 0;
}

html body .ck {
  
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;

  a {
    text-decoration: underline;

    &.no-underline {
      text-decoration: none;
    }

    &:not([class*='text-']) {
      color: rgb(var(--color-blue-darkest));
    }
  }

  .quick-links {
    a {
      color: #232c61;
    }
  }

  h2, h3, h4, h5, h6 {
    font-weight: 500;
    margin: 1rem 0 .5rem;

    a:not([class*='text-']) {
      text-decoration: none;
      color: inherit;
    }
  }

  h2 {
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin: 1.5rem 0 .5rem;
  }

  ul:not(.list-none) {
    list-style-type: disc;
    ul {
      list-style: circle;
      ul {
        list-style: square;
        ul {
          list-style-type: disc;
          ul {
            list-style: circle;
            ul {
              list-style: square;
            }
          }
        }
      }
    }
  }

  ol:not(.list-none) {
    list-style-type:decimal;
    ol {
      list-style-type:lower-alpha;
      ol {
        list-style-type: lower-roman;
        ol {
          list-style-type:decimal;
          ol {
            list-style-type:lower-alpha;
            ol {
              list-style-type: lower-roman;
            }
          }
        }
      }
    }
  }

  ul:not(.list-none), ol:not(.list-none) {
    padding-left: 1rem;

    > li {
      margin-bottom: 0.5rem;
    }
  }

  ul.checklist {
    li{
      padding-left: 0.25em;
      
      &::marker {
        content: '☐ ';
        color: rgb(var(--color-navy-base))
      }
   }
  }

  img {
    display: inline;
    filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
    border-radius: 0.25rem; 
  }

  .headerlink {
    visibility: hidden;
  }

  *:hover > .headerlink {
    visibility: visible;
  }

  code {
    @apply bg-gray-400 text-navy-600 rounded;
  }

  pre {
    @apply overflow-x-auto max-w-full bg-navy-600 rounded w-full;
    code {

      @apply bg-transparent text-white;

      &.hljs {
        padding-top: 1.5rem;
        @apply p-3;
      }
    }
  } 

  .highlight pre:not(code) {
    background-color: rgb(var(--color-navy-base));
    color: white;
    padding: 1rem;
    border-radius: .3rem;
    font-size: 80%;
    line-height: 1.25rem;
    overflow-x: auto; /* for enabling horizontal scroll */
  }

  .rn-tag {
    text-transform: uppercase;
    padding: .2em .3rem;
    background-color: rgb(var(--color-gray-dark));
    color: white;
    border-radius: .3rem;
    font-weight: 500;
    text-align: center;
    font-size: 80%;
  }

  .rn-tag-blue {
    background-color: rgb(var(--color-blue-dark));
  }

  .rn-tag-yellow {
    background-color: rgb(var(--color-yellow-dark));
  }

  .rn-tag-green {
    background-color: rgb(var(--color-teal-base));
  }

  .external.reference::after {
    margin-left: 0;
  }

  .table-overflow-auto {
    overflow: auto;
  }

  table {  
    border: solid 1px rgb(var(--color-gray-light)) !important;
    margin: 1em 0;
    width: 100%;
    
    tr {
      border: solid 1px rgb(var(--color-gray-light));
    }
    
    tbody {
      .row-odd {
        background-color: var(--color-white);
      }
      .row-even {
        background-color: rgb(var(--color-gray-lightest));
      }
    }
    thead {
      tr {
        background-color: rgb(var(--color-gray-light));
        font-family: "Proxima Nova", "Helvetica Neue","Helvetica","Roboto","Arial";
        font-weight: bold;
      }
    }
    
    th {
      text-align: start;
      font-size: initial;
    }
    
    th, td {
      padding: 0.5rem 1rem !important;

      p:first-child {
        margin-top: 0;
      }
      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  p {
    margin: .8rem 0;
  }
}  
  
.admonition {
  border: 1px rgb(var(--color-primary-blue)) solid;
  padding: 1rem;
  background-color: white;
  border-radius: .3rem;
  margin: 0.8rem 0;
}

.admonition.note {
  border-color: rgb(var(--color-primary-blue));
}
.admonition.note .admonition-title {
  color: rgb(var(--color-primary-blue));
}
.admonition.important {
  border-color: rgb(var(--color-orange-base));
}
.admonition.important .admonition-title {
  color: rgb(var(--color-orange-dark));
}
.admonition.caution {
  border-color: rgb(var(--color-orange-dark));
}
.admonition.caution .admonition-title {
  color: rgb(var(--color-orange-dark));
}
.admonition.warning {
  border-color: rgb(var(--color-yellow-dark));
}
.admonition.warning .admonition-title {
  color: rgb(var(--color-yellow-dark));
}
.admonition.advisory {
  border-color: rgb(var(--color-pink-dark));
}
.admonition.advisory .admonition-title {
  color: rgb(var(--color-pink-dark));
}
.admonition.alert {
  border-color: rgb(var(--color-pink-darkest));
}
.admonition.alert .admonition-title {
  color: rgb(var(--color-pink-darkest));
}
  
.ck .admonition-title {
  margin: 0;
  font-family: "Proxima Nova", "Helvetica Neue","Helvetica","Roboto","Arial";
  font-weight: bold;
  color: rgb(var(--color-gray-darker));
}
  
.message-status {
  padding: 0.5rem 1rem;
  background-color: white;
  border-radius: .35rem;
  margin: 5px 0;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  border-radius: 0.5rem; 
}
.message-status p {
  margin: 0.5rem 0;
}

.quick-links a {
  background: white url(/assets/droplet-badge.svg) no-repeat;
  background-position: 1rem;
}

.ck-content pre[data-language] {
  background-color: rgb(var(--color-navy-base));
  border-radius: .3rem;
}

.ck-content blockquote {
  font-size: medium;
}

.ck blockquote {
  font-style: italic;
  margin-left: 0;
  margin-right: 0;
  overflow: hidden;
  padding-left: 1.5em;
  padding-right: 1.5em;
  position: relative;
}

.ck blockquote::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0.45rem;
  height: 100%;
  background-color: rgb(var(--color-navy-light));
  border-radius: 2px;
}

  .sphinx-tabs {
    margin-bottom: 1rem;
  }
  
  [role="tablist"] {
    border-bottom: 1px solid #a0b3bf;
  }
  
  .sphinx-tabs-tab {
    position: relative;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    color: #1D5C87;
    line-height: 24px;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 5px 5px 0 0;
    border: 0;
    padding: 1rem 1.5rem;
    margin-bottom: 0;
  }
  
  .sphinx-tabs-tab[aria-selected="true"] {
    font-weight: 700;
    border: 1px solid #a0b3bf;
    border-bottom: 1px solid white;
    margin: -1px;
    background-color: white;
  }
  
  .sphinx-tabs-tab:focus {
    z-index: 1;
    outline-offset: 1px;
  }
  
  .sphinx-tabs-panel {
    position: relative;
    padding: 1rem;
    border: 1px solid #a0b3bf;
    margin: 0px -1px -1px -1px;
    border-radius: 0 0 5px 5px;
    border-top: 0;
    background: white;
  }
  
  .sphinx-tabs-panel.code-tab {
    padding: 0.4rem;
  }
  
  .sphinx-tab img {
    margin-bottom: 24 px;
  }
  
  /* Dark theme preference styling */
  
  @media (prefers-color-scheme: dark) {
    body[data-theme="auto"] .sphinx-tabs-panel {
      color: white;
      background-color: rgb(50, 50, 50);
    }
  
    body[data-theme="auto"] .sphinx-tabs-tab {
      color: white;
      background-color: rgba(255, 255, 255, 0.05);
    }
  
    body[data-theme="auto"] .sphinx-tabs-tab[aria-selected="true"] {
      border-bottom: 1px solid rgb(50, 50, 50);
      background-color: rgb(50, 50, 50);
    }
  }
  
  /* Explicit dark theme styling */
  
  body[data-theme="dark"] .sphinx-tabs-panel {
    color: white;
    background-color: rgb(50, 50, 50);
  }
  
  body[data-theme="dark"] .sphinx-tabs-tab {
    color: white;
    background-color: rgba(255, 255, 255, 0.05);
  }
  
  body[data-theme="dark"] .sphinx-tabs-tab[aria-selected="true"] {
    border-bottom: 2px solid rgb(50, 50, 50);
    background-color: rgb(50, 50, 50);
  }


.ck-editor {
  

  .widget-faq-add {
    @apply rounded-lg bg-gray-300 border-dashed border border-gray-600 px-2
  }
  .widget-faq-add:before {
    content: "Place the cursor here to insert an additional FAQ.";
    @apply text-gray-600 italic text-center
  }

  
  .widget-tabs-add {
    @apply rounded-lg bg-gray-300 border-dashed border border-gray-600 px-2
  }
  .widget-tabs-add:before {
    content: "Place the cursor here to insert an additional tab.";
    @apply text-gray-600 italic text-center
  }

  .widget-status-message {
    @apply block
  }

  .widget-quick-link {
    @apply border border-gray-600 bg-gray-400 p-2 
  }

  .widget-toc {
    @apply bg-gray-600 rounded text-white p-2 
  }
}